@import url(https://fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap);
#root {
  display: grid;
  min-height: 100vh;
}

body {
  font-family: 'Open Sans';
  background-color: #f5f2ef;
  color: #39393a;
  margin: 0px;
}

#page-wrapper {
  width: 40vw;
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto';
  letter-spacing: 0.8px;
}

input,
button {
  border: none;
}

small {
  color: #333;
  font-style: italic;
}

.main-title {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.message-area {
  max-width: 50vw;
  margin: 3vh auto 0 auto;
  background-color: #fff;
  padding: 1.6em;
  border-radius: 20px;
  box-shadow: 0 5px 0.5rem rgb(20, 20, 20, 0.2);
}
.message-area .messages {
  height: 45vh;
  overflow: auto;
}
.message-area-formatting {
  margin-bottom: 4vh;
}
ul.messages {
  padding: 0;
}
.message-box-width {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  /* height: 130px  */
}
.message-box-width:last-of-type {
  /* padding-bottom: 3em; */
}
.send-reset-flex {
  display: flex;
  overflow: hidden;
  padding-bottom: 15px;
}

.input-area {
  width: 100%;
  /* margin-top: 2em; */
}

.accorMain {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.accorHead {
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.accor {
  display: none;
}

.accorHead,
.logoImg {
  cursor: pointer;
}
.logoImgSmaller {
  width: 6% !important;
}
@media (max-width: 873px) {
  .logoImgSmaller {
    width: 7% !important;
  }
}
@media(max-width: 600px) {
  .logoImgSmaller {
    width: 9% !important;
  }
}
@media(max-width: 460px){ 
  .logoImgSmaller {
    width: 12% !important;
  }
}
@media(max-width: 374px) {
  .logoImgSmaller {
    width: 15% !important; 
  }
}
@media (max-width: 300px) {
  .logoImgSmaller {
    width: 20% !important;
  }
}
.accorBody {
  background: white;
  position: absolute;
  padding: 0 10px;
  margin-top: 10px;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
}

.accorActive > .accorBody {
  padding: 10px 20px;
  max-height: 600px;
}

#dateInput {
  display: flex;
  justify-content: center;
}

.joinedForm {
  display: flex;
}

#userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#downArrow {
  font-size: 0.6em;
  color: #b6b6b6;
}

#message-title {
  text-align: center;
}

.multipleChoiceButtons {
  display: flex;
  margin-top: 5px;
  margin-left: 5.5vw;
}

.multButtonAndQues {
  display: flex;
  flex-direction: column;
}

#footer {
  position: absolute;
  bottom: 0;
}

.app {
  position: relative;
  min-height: 100vh;
}

.circleAnimContainer {
  margin-left: 1em;
  font-size: 1.5em;
}

@media only screen and (max-width: 600px) {
  #page-wrapper {
    width: 90vw;
  }
  .message-area {
    max-width: 80vw;
  }
}

/* FORM ERRORS */
.error-message {
  color: red;
}

@media (max-width: 725px) {
  .accor {
    display: block;
  }

  .input-area {
    display: flex;
    flex-direction: column;
  }
  .input-area button {
    width: 100%;
  }
  .input-area input[type='text'] {
    text-indent: 15px;
    width: 100%;
    padding: 1em 0;
  }
}
@media (max-width: 410px) {
  .reset-btn {
    height: 100%;
  }
}

